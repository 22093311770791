<template>
  <fw-layout
    :loading="initialLoading && loading"
    :notfound="initialLoading === false && procedure === null"
    :loading-title="procedureFullCode"
    mobile-ready
  >
    <template v-if="showTimeCounter" #header-toolbar>
      <TimeCounter :remaining-seconds="remainingSeconds" class="mr-5" />
    </template>

    <template v-if="procedure" #main-content>
      <DashboardHero :title-label="$t('procedureCode')" :tag="$t(`procedureType.${procedure.type}`)" class="mt-2 mb-5">
        <template #toolbar>
          <div v-if="isOpenToApplications">
            <div class="inline-flex flex-shrink-0 text-primary relative gap-2">
              <div class="font-semibold">{{ $t('openApplications') }}</div>
              <fw-dot color="primary" inline class="mt-1" />
            </div>
          </div>
          <div v-else-if="isClosedToApplications">
            <div class="text-sm opacity-70 font-medium">
              {{ $t('applicationState.closed') }}
            </div>
          </div>
          <div v-else-if="isOpeningSoon">
            <div class="text-primary font-semibold">{{ $t('soon') }}</div>
          </div>
        </template>
        <template #default>
          <div class="flex justify-center mb-5">
            <div v-if="!hasApplication && canApply">
              <fw-button type="primary" size="lg" @click.native="startApplication">{{
                $t('startApplication')
              }}</fw-button>
            </div>
            <div v-else-if="hasApplication" class="text-white flex flex-col gap-2">
              <div>
                <fw-button type="white" size="md" @click.native="goToApplication(procedure.application)">
                  {{ $t('applicationState.see') }}
                </fw-button>
              </div>
              <div class="text-xs opacity-80">
                {{ $t('startedAt') }} {{ procedure.application.created_date | formatDateTime }}
              </div>
            </div>
          </div>
        </template>
        <template #title>
          <fw-heading size="h1">
            <span class="font-medium opacity-80">{{ procedure.prefix }}</span>
            <span>{{ procedure.code }}</span>
          </fw-heading>
        </template>
        <template #footer>
          <div>
            <fw-label color="text-white opacity-50">{{
              isProcedureTypeScholarship ? $t('scholarshipsNumber') : $t('seatsNumber')
            }}</fw-label>
            <div>{{ procedure.number_of_seats || $t('notDefined') }} {{ $tc('place', procedure.number_of_seats) }}</div>
          </div>
          <div>
            <fw-label color="text-white opacity-50">{{ $t('publishedDRDate') }}</fw-label>
            <div>
              {{ procedure.published_dr_date | formatDate }}
            </div>
          </div>
          <div>
            <fw-label color="text-white opacity-50">{{ $t('rangeToApply') }}</fw-label>
            <div>
              {{ procedure.publish_start | formatDate }} {{ $t('to') }} {{ procedure.publish_end | formatDate }}
            </div>
          </div>
        </template>
      </DashboardHero>

      <div
        class="p-2 max-w-screen-md mx-auto text-xl mt-8 mb-2 text-center font-medium"
        v-html="
          $tc('scholarships.description', procedure.number_of_seats, {
            project: procedure.scholarship_project.name[language],
            reference: procedure.scholarship_project.reference,
            finance: procedure.finance_source.text[language],
          })
        "
      ></div>

      <div class="flex justify-center gap-5 mb-5 text-xl max-w-screen-md mx-auto font-medium">
        <div class="flex flex-col items-center">
          <fw-label>{{ $t('duration') }}</fw-label>
          <div>{{ procedure.duration }} {{ $tc('durationUnit', procedure.duration) }}</div>
        </div>
        <div class="flex flex-col items-center">
          <fw-label>{{ $t('value') }}</fw-label>
          <div>{{ procedure.monthly_schoolarship_value | currency }} /{{ $t(`valueMonths`) }}</div>
        </div>
        <div v-if="procedure.tuition_payment || procedure.is_renewable" class="flex flex-col items-center ml-5">
          <fw-label>{{ $t('conditions') }}</fw-label>
          <div class="flex gap-2">
            <div v-if="procedure.tuition_payment" class="text-base flex gap-1 items-center p-1">
              <svg
                class="fill-current w-4 h-4 flex-shrink-0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M2 20h20v2H2v-2zm2-8h2v7H4v-7zm5 0h2v7H9v-7zm4 0h2v7h-2v-7zm5 0h2v7h-2v-7zM2 7l10-5 10 5v4H2V7zm2 1.236V9h16v-.764l-8-4-8 4zM12 8a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                />
              </svg>
              <div>
                {{ $t(`tuitionFee`) }}
              </div>
            </div>
            <div v-if="procedure.is_renewable" class="text-base flex items-center gap-1 p-1">
              <svg
                class="fill-current h-4 w-4 flex-shrink-0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0H24V24H0z" />
                <path
                  d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12h2c0 4.418 3.582 8 8 8s8-3.582 8-8-3.582-8-8-8C9.25 4 6.824 5.387 5.385 7.5H8v2H2v-6h2V6c1.824-2.43 4.729-4 8-4zm1 5v4.585l3.243 3.243-1.415 1.415L11 12.413V7h2z"
                />
              </svg>
              {{ $t(`renewable`) }}
            </div>
          </div>
        </div>
      </div>

      <fw-panel
        v-if="procedure.type === 'scholarships' && procedure.scholarship_project"
        :title="$t(`project`)"
        featured
        class="my-5"
        boxed="sm"
      >
        <div class="flex flex-col gap-3">
          <div>
            <fw-label>{{ $t(`name`) }}</fw-label>
            <div>{{ procedure.scholarship_project.name[language] }}</div>
          </div>
          <div>
            <fw-label>{{ $t(`scientificArea`) }}</fw-label>
            <div>{{ procedure.scholarship_project.scientific_area[language] }}</div>
          </div>
          <div>
            <fw-label>{{ $t(`requirements`) }}</fw-label>
            <div>{{ procedure.scholarship_project.requirements[language] }}</div>
          </div>
          <div v-if="procedure.candidate_profile">
            <fw-label>{{ $t(`profile`) }}</fw-label>
            <div>{{ procedure.candidate_profile[language] }}</div>
          </div>
          <div>
            <fw-label>{{ $t(`goals`) }}</fw-label>
            <div>{{ procedure.scholarship_project.goals[language] }}</div>
          </div>
          <div v-if="procedure.location">
            <fw-label>{{ $t(`location`) }}</fw-label>
            <div>{{ procedure.location }}</div>
          </div>
          <div v-if="procedure.docs_to_submit && procedure.docs_to_submit[language]">
            <div class="font-semibold my-1 text-xs text-gray-400">
              {{ $t('docsToSubmit') }}
            </div>
            <div class="whitespace-pre-line">{{ procedure.docs_to_submit[language] }}</div>
          </div>
          <div
            v-if="
              procedure.scholarship_project &&
                procedure.scholarship_project.advisors &&
                procedure.scholarship_project.advisors.length > 0
            "
          >
            <div class="font-semibold my-1 text-xs text-gray-400">
              {{ $t(`orientation`) }}
            </div>
            <div class="rounded-md overflow-hidden bg-white">
              <PersonBasic
                v-for="person in procedure.scholarship_project.advisors"
                :key="person.key"
                :user="person"
                class="my-3"
              />
            </div>
          </div>
        </div>
      </fw-panel>

      <fw-panel v-if="procedure && procedure.type === 'scholarships'" :title="$t(`evaluation`)" featured class="my-5">
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-5">
          <template v-for="mainCriteria in ['curriculum_criteria', 'interview_criteria']">
            <div
              v-if="procedure && procedure[mainCriteria] && procedure[mainCriteria].length > 0"
              :key="mainCriteria.$key"
              class="bg-white p-4 rounded-xl flex flex-wrap sm:flex-nowrap gap-5"
            >
              <div>
                <div
                  class="w-16 h-16 text-center text-md font-bold border-gray-700 border-2 rounded-xl flex justify-center items-center"
                >
                  {{ procedure[mainCriteria === 'curriculum_criteria' ? 'curriculum_weight' : 'interview_weight'] }}%
                </div>
              </div>
              <div class="flex-1">
                <div class="text-lg font-semibold pt-1.5">
                  {{ mainCriteria === 'curriculum_criteria' ? $t(`curriculum`) : $t(`interview`) }}
                </div>
                <div
                  v-for="(criteria, c) in procedure[mainCriteria]"
                  :key="'curriculum_' + c"
                  class="flex items-center gap-2.5 mt-2"
                >
                  <div class="text-sm flex-1">{{ criteria.name[language] }}</div>
                  <div class="inline-flex items-center gap-2">
                    <div class="bg-gray-300 h-2 w-2 rounded-full bg-opacity-70"></div>
                    <div class="text-sm">{{ criteria.value }}%</div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </fw-panel>

      <fw-panel
        v-if="procedure && procedure.type === 'scholarships' && jury.length > 0"
        :title="$t('jury')"
        featured
        boxed="sm"
        custom-class="grid grid-cols-1 gap-3"
      >
        <PersonBasic v-for="person in jury" :key="person.key" :user="person" />
      </fw-panel>

      <fw-panel
        v-if="procedure && procedure.updates && procedure.updates.length"
        :title="$t('updates')"
        featured
        boxed="sm"
        class="my-5"
      >
        <RecordProcedureUpdate
          v-for="(update, u) in procedure.updates"
          :key="'update_' + u"
          :language="language"
          :item="update"
          class="my-3"
        />
      </fw-panel>
    </template>
  </fw-layout>
</template>

<script>
import RecordProcedureUpdate from '@/components/records/RecordProcedureUpdate'
import DashboardHero from '@/fw-modules/fw-core-vue/ui/components/dashboard/DashboardHero'
import TimeCounter from '@/fw-modules/fw-core-vue/forms/components/text/TimeCounter'
import PersonBasic from '@/fw-modules/fw-core-vue/ui/components/cards/PersonBasic'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    PersonBasic,
    TimeCounter,
    DashboardHero,
    RecordProcedureUpdate,
  },

  data() {
    return {
      initialLoading: true,
      loading: false,
      procedure: null,

      // Counter
      remainingSeconds: 0,
      remainingSecondsToShowCounter: 3600, // 1 hour
      timerInterval: null,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    language() {
      return this.$store.state.language
    },
    isProcedureTypeScholarship() {
      return this.procedure !== null && this.procedure.type == 'scholarships'
    },
    procedureFullCode() {
      return this.procedure ? this.procedure.prefix + this.procedure.code : this.$route.params.code
    },
    tuitionFeeFootnoteNumber() {
      return 1
    },
    renewableFootnoteNumber() {
      if (this.procedure && this.procedure.tuition_payment === true) {
        return 2
      } else {
        return 1
      }
    },
    jury() {
      return this.procedure ? this.procedure.presidents.concat(this.procedure.vogals) : []
    },

    // Checks
    isOpenToApplications() {
      return (
        this.procedure &&
        this.procedure.time_for_publish_end > 0 &&
        this.procedure.time_for_publish_start < 0 &&
        this.remainingSeconds > 0 &&
        this.procedure.public_status == 'open'
      )
    },
    isClosedToApplications() {
      return this.procedure && this.procedure.time_for_publish_end < 1
    },
    isOpeningSoon() {
      return this.procedure.time_for_publish_start > 0 && this.procedure.status == 'published'
    },
    hasApplication() {
      return Object.keys(this.procedure.application).length > 0
    },
    canApply() {
      return this.isOpenToApplications && !this.hasApplication
    },
    showTimeCounter() {
      return (
        this.isOpenToApplications &&
        this.timerInterval != null &&
        this.remainingSeconds < this.remainingSecondsToShowCounter
      )
    },
  },

  mounted() {
    this.getProcedure()
  },

  beforeDestroy() {
    this.stopTime()
  },

  methods: {
    async getProcedure() {
      this.loading = true
      utils.tryAndCatch(
        this,
        async () => {
          this.procedure = await this.api.getProcedure(this.$route.params.code)
          // Update remaining time to submit application
          this.remainingSeconds = this.procedure.time_for_publish_end
          // Start clock
          if (this.remainingSeconds > 0) this.startTime()
        },
        () => {
          setTimeout(() => {
            this.loading = false
            this.initialLoading = false
          }, 750)
        }
      )
    },

    // Actions
    startApplication() {
      this.$buefy.dialog.confirm({
        title: this.$t('applicationState.start'),
        message: this.$t('applicationState.startConfirm') + this.procedure.prefix + this.procedure.code + '?',
        confirmText: this.$t('start'),
        type: 'is-primary',
        onConfirm: async () => {
          this.$router.push(`/application/${this.procedure.prefix}${this.procedure.code}/start`)
        },
      })
    },
    goToApplication(application) {
      let view = application.status === 'submitted' ? 'dashboard' : 'metadata'
      this.$router.push('/application/' + this.procedureFullCode + '/' + view)
    },

    // Deal with time
    stopTime() {
      if (this.timerInterval !== null) {
        clearInterval(this.timerInterval)
        this.timerInterval = null
      }
    },

    startTime() {
      let self = this
      this.timeTick()

      if (this.timerInterval != null) {
        clearInterval(this.timerInterval)
      }
      this.timerInterval = setInterval(() => {
        self.timeTick()
      }, 1000)

      console.log('Timer started')
    },

    timeTick() {
      this.remainingSeconds -= 1

      // Check if time is over
      if (this.remainingSeconds <= 0) {
        this.stopTime()
        this.logActivity('time-is-over-warning-auto-cancel')
        // Redirect to call page
        setTimeout(() => {
          this.$router.go()
        }, 250)
      }
    },

    // Log activity
    logActivity(action, metadata = {}, byUserInteraction = true) {
      this.$store.dispatch('saveActivityAction', {
        service: 'apply',
        metadata: metadata,
        action: action,
        elementType: 'procedure',
        elementID: this.procedure != null ? this.procedure.key : 'NULL',
        userInteraction: byUserInteraction,
      })
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "start": "Iniciar",
    "place": "vaga | vagas",
    "rangeToApply": "Candidaturas",
    "to": "a",
    "scholarshipsNumber": "Vagas",
    "publishedDRDate": "Data de publicação",
    "publishStartDate": "Início de candidaturas",
    "publishEndDate": "Fim de candidaturas",
    "procedureCode": "Código do procedimento",
    "procedureNotAvailable": "Procedimento não disponível",
    "applicationDates": "Prazos de candidatura",
    "openApplications": "Candidaturas Abertas",
    "applicationEnds": "As candidaturas terminaram a ",
    "scholarships": {
      "description": "Concurso para atribuição de <b>{n}</b> bolsa de investigação, no âmbito do projeto <b>{project}</b>, com a referência {reference} e {finance} | Concurso para atribuição de <b>{n}</b> bolsas de investigação, no âmbito do projeto <b>{project}</b>, com a referência {reference} e com o título {name}, {finance}"
    },
    "deadlines": "Prazo de candidaturas",
    "publishOn": "Publicado a",
    "startSoon": "Inicia em breve",
    "startApplication": "Iniciar Candidatura",
    "notDefined": "Não definido",
    "startedAt": "Iniciada em",
    "ended": "Candidaturas terminadas",
    "value": "Valor",
    "duration": "Duração",
    "tuitionFee": "Pagamento de propinas",
    "valueMonths": "mês",
    "renewable": "Renovável",
    "durationUnit": "mês | meses",
    "project": "Projeto",
    "name": "Nome",
    "scientificArea": "Área Científica",
    "requirements": "Requisitos",
    "profile": "Perfil do candidato",
    "goals": "Plano de trabalhos / objetivos",
    "location": "Local de realização",
    "orientation": "Orientação Científica",
    "evaluation": "Avaliação",
    "curriculum": "Avaliação curricular",
    "interview": "Entrevista",
    "conditions": "Condições",
    "notes": "Notas e condições",
    "tuitionNotes": "Prevê pagamento de proprinas.",
    "tuitionNotes": "Possivelmente renovável.",
    "footnote": "Consulte todas as condições no edital publicado nas Publicações deste procedimento.",
    "applicationDates": "Prazos de candidatura",
    "applicationEnds": "As candidaturas terminaram a ",
    "docsToSubmit": "Documentos a entregar",
    "jury": "Júri",
    "updates": "Publicações",
    "applicationsOpenSoon": "Candidaturas abertas em breve",
    "asOf": "As of",
    "soon": "Brevemente",
    "procedureType": {
      "teachers": "Professores",
      "researchers": "Investigadores",
      "researchers-dl57": "Investigadores DL 57",
      "scholarships": "Bolsas",
      "staff": "Pessoal não docente"
    },
    "applicationState": {
      "see": "Ver candidatura",
      "start": "Abrir candidatura",
      "startConfirm": "Tem a certeza que pretende iniciar uma candidatura no procedimento ",
      "cancel": "Cancelar candidatura",
      "cancelConfirm": "Tem a certeza que pretende cancelar a sua candidatura? Até ao final do prazo de candidatura, poderá reabrir e continuar o processo.",
      "cancelYes": "Sim",
      "cancelNo": "Não",
      "reopen": "Reabrir candidatura",
      "reopenConfirm": "Ao reabrir a candidatura, a mesma voltará ao estado de rascunho e só será considerada caso seja novamente submetida.",
      "reopenDone": "A sua candidatura foi reaberta.",
      "submit": "Submeter candidatura",
      "submitConfirm": "Tem a certeza que deseja submeter a sua candidatura?",
      "submitted": "Candidatura submetida",
      "submittedModal": "Até ao fim do prazo de abertura, pode reabrir ou mesmo cancelar a sua candidatura. Em caso de reabertura, deverá voltar a submeter a mesma para que o processo seja considerado.",
      "saved": "Os dados da sua candidatura foram guardados",
      "closed": "Candidaturas encerradas",
      "closedDescription": "Infelizmente, o prazo de candidatura para este procedimento terminou. A sua candidatura será considerada cancelada.",
      "closedIn": "Candidaturas terminaram no dia",
      "internalError": "Ocorreu um erro a submeter a sua candidatura. Tente mais tarde ou deixe-nos uma mensagem através da área de suporte.",
      "endsToday": "Termina hoje",
      "endsTomorrow": "Termina amanhã",
      "endsIn": "Termina em"
    }
  },
  "en": {
    "start": "Start",
    "place": "seat | seats",
    "procedureCode": "Procedure code",
    "rangeToApply": "Open to applications",
    "to": "to",
    "scholarshipsNumber": "Seats",
    "publishedDRDate": "Published date",
    "publishStartDate": "Open of applications",
    "publishEndDate": "End of applications",
    "procedureNotAvailable": "Procedure not available",
    "applicationDates": "Application deadline",
    "openApplications": "Applications Open",
    "applicationEnds": "Applications ended on ",
    "startedAt": "Started at",
    "scholarships": {
      "description": "Call for <b>{n}</b> research fellowship, in the framework of the <b>{project}</b>, with reference {reference} and {finance} | Call for <b>{n}</b> research fellowships, in the framework of the <b>{project}</b>, with reference {reference} and title {name}, {finance}"
    },
    "soon": "Soon",
    "conditions": "Conditions",
    "notDefined": "Not defined",
    "applicationsOpenSoon": "Applications coming soon",
    "asOf": "A partir de",
    "deadlines": "Application deadlines",
    "publishOn": "Published on",
    "startApplication": "Start Application",
    "startSoon": "Starts soon",
    "ended": "Applications closed",
    "value": "Value",
    "duration": "Duration",
    "valueMonths": "month",
    "tuitionFee": "Tuition payment",
    "renewable": "Renewable",
    "durationUnit": "month | months",
    "project": "Project",
    "name": "Name",
    "scientificArea": "Scientific Area",
    "requirements": "Requirements",
    "profile": "Candidate Profile",
    "goals": "Work plans / Goals",
    "location": "Location",
    "orientation": "Scientific Orientation",
    "evaluation": "Evaluation",
    "curriculum": "Curriculum",
    "interview": "Interview",
    "notes": "Notes and conditions",
    "tuitionNotes": "Forsee tuition fee payment.",
    "tuitionNotes": "Possibly renewable.",
    "footnote": "See all the conditions in the public notice published in the publications of this procedure.",
    "applicationDates": "Application deadline",
    "applicationEnds": "Applications ended on ",
    "docsToSubmit": "Documents to submit",
    "jury": "Jury",
    "updates": "Publications",
    "procedureType": {
      "teachers": "Teachers",
      "researchers": "Researchers",
      "researchers-dl57": "Researchers DL 57",
      "scholarships": "Scholarships",
      "staff": "Staff"
    },
    "applicationState": {
      "see": "See application",
      "start": "Start application",
      "startConfirm": "Are you sure you want to start an application for the procedure ",
      "cancel": "Cancel application",
      "cancelConfirm": "Are you sure you want to cancel the submission of your application? You'll be able to reopen and finalize this application until the deadline.",
      "cancelYes": "Sure, cancel",
      "cancelNo": "Do not cancel",
      "reopen": "Reopen application",
      "reopenConfirm": "By reopening your application, its status will be changed to draft and the application must be submitted again to become valid.",
      "reopenDone": "Your application was reopen.",
      "submit": "Submit applicattion",
      "submitConfirm": "Are you sure you want to submit your application?",
      "submitted": "Application submitted",
      "submittedModal": "Até ao fim do prazo de abertura, pode reabrir ou mesmo cancelar a sua candidatura. Em caso de reabertura, deverá voltar a submeter a mesma para que o processo seja considerado.",
      "saved": "Application data was saved.",
      "closed": "Applications closed",
      "closedDescription": "Unfortunately, the deadline for this procedure has expired. Your application will not be considered.",
      "closedIn": "Applications closed in",
      "internalError": "There was an error while submitting your application. Please, try again later or create a support request.",
      "endsToday": "Ends today",
      "endsTomorrow": "Ends tomorrow",
      "endsIn": "Ends in"
    }
  }
}
</i18n>
