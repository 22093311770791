<template>
  <div class="record-procedure-update relative pl-11 pb-3">
    <div
      class="record-icon absolute top-0 left-0 h-8 w-8 bg-white rounded-full flex flex-col items-center justify-center  z-10"
    >
      <svg
        v-if="item.files.length"
        class="fill-current text-gray-600 h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          d="M21 8v12.993A1 1 0 0 1 20.007 22H3.993A.993.993 0 0 1 3 21.008V2.992C3 2.455 3.449 2 4.002 2h10.995L21 8zm-2 1h-5V4H5v16h14V9zM8 7h3v2H8V7zm0 4h8v2H8v-2zm0 4h8v2H8v-2z"
        />
      </svg>
      <svg
        v-else
        class="fill-current text-gray-600 h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          d="M2 3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993zM4 5v14h16V5H4zm2 2h6v6H6V7zm2 2v2h2V9H8zm-2 6h12v2H6v-2zm8-8h4v2h-4V7zm0 4h4v2h-4v-2z"
        />
      </svg>
    </div>
    <div class="h-full bg-gray-200 absolute top-0" style="width: 2px; left: 13px;"></div>
    <div class="pt-0.5">
      <div v-if="item.title" class="font-semibold leading-4">
        {{
          typeof item.title === 'string'
            ? item.title
            : item.title[language]
            ? item.title[language]
            : language === 'pt'
            ? 'Sem título'
            : 'Untitled'
        }}
      </div>
      <div class="text-grey-400 text-xs leading-5">{{ item.publish_date | formatDate }}</div>
      <div
        v-if="item.description"
        class="text-sm mt-1 mb-2"
        v-html="
          typeof item.description === 'string'
            ? item.description
            : item.description[language]
            ? item.description[language]
            : ''
        "
      ></div>

      <div v-if="item.files.length">
        <template v-for="(file, f) in files">
          <RecordFileEntry
            :key="file.$index"
            :can-edit="false"
            :can-remove="false"
            class="px-2"
            :class="{ 'border-b border-gray-100': f !== files.length - 1 }"
            :file="file"
            @download="downloadFile"
          ></RecordFileEntry>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import RecordFileEntry from '../../fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'

import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

export default {
  name: 'ProcedureUpdateRecord',
  components: {
    RecordFileEntry,
  },
  props: {
    item: Object,
    language: {
      type: String,
      default: 'pt',
    },
    hasNext: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    files() {
      let localeFiles = this.item.files.filter(el => el.locale === this.language)
      if (!localeFiles || localeFiles.length < 1) localeFiles = this.item.files.filter(el => el.locale != this.language)
      return localeFiles
    },
  },
  methods: {
    async downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },
  },
}
</script>
