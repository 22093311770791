var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"record-procedure-update relative pl-11 pb-3"},[_c('div',{staticClass:"record-icon absolute top-0 left-0 h-8 w-8 bg-white rounded-full flex flex-col items-center justify-center z-10"},[(_vm.item.files.length)?_c('svg',{staticClass:"fill-current text-gray-600 h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","width":"24","height":"24"}},[_c('path',{attrs:{"fill":"none","d":"M0 0h24v24H0z"}}),_c('path',{attrs:{"d":"M21 8v12.993A1 1 0 0 1 20.007 22H3.993A.993.993 0 0 1 3 21.008V2.992C3 2.455 3.449 2 4.002 2h10.995L21 8zm-2 1h-5V4H5v16h14V9zM8 7h3v2H8V7zm0 4h8v2H8v-2zm0 4h8v2H8v-2z"}})]):_c('svg',{staticClass:"fill-current text-gray-600 h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","width":"24","height":"24"}},[_c('path',{attrs:{"fill":"none","d":"M0 0h24v24H0z"}}),_c('path',{attrs:{"d":"M2 3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993zM4 5v14h16V5H4zm2 2h6v6H6V7zm2 2v2h2V9H8zm-2 6h12v2H6v-2zm8-8h4v2h-4V7zm0 4h4v2h-4v-2z"}})])]),_c('div',{staticClass:"h-full bg-gray-200 absolute top-0",staticStyle:{"width":"2px","left":"13px"}}),_c('div',{staticClass:"pt-0.5"},[(_vm.item.title)?_c('div',{staticClass:"font-semibold leading-4"},[_vm._v(" "+_vm._s(typeof _vm.item.title === 'string' ? _vm.item.title : _vm.item.title[_vm.language] ? _vm.item.title[_vm.language] : _vm.language === 'pt' ? 'Sem título' : 'Untitled')+" ")]):_vm._e(),_c('div',{staticClass:"text-grey-400 text-xs leading-5"},[_vm._v(_vm._s(_vm._f("formatDate")(_vm.item.publish_date)))]),(_vm.item.description)?_c('div',{staticClass:"text-sm mt-1 mb-2",domProps:{"innerHTML":_vm._s(
        typeof _vm.item.description === 'string'
          ? _vm.item.description
          : _vm.item.description[_vm.language]
          ? _vm.item.description[_vm.language]
          : ''
      )}}):_vm._e(),(_vm.item.files.length)?_c('div',[_vm._l((_vm.files),function(file,f){return [_c('RecordFileEntry',{key:file.$index,staticClass:"px-2",class:{ 'border-b border-gray-100': f !== _vm.files.length - 1 },attrs:{"can-edit":false,"can-remove":false,"file":file},on:{"download":_vm.downloadFile}})]})],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }