<template>
  <div class="flex gap-2">
    <div>
      <fw-avatar class="flex-shrink-0" size="sm" :user="user" />
    </div>
    <div class="leading-5 flex flex-col justify-center">
      <div class="flex gap-2">
        <v-clamp autoresize :max-lines="1" class="font-semibold">
          {{ getPersonName() }}
        </v-clamp>
        <div v-if="number && user.number" class="text-xs text-gray-500">
          {{ user.number }}
        </div>
        <div v-if="itsYou">
          <fw-icon-start-smile-solid class="w-4 h-4 text-primary" />
        </div>
      </div>
      <div
        v-if="email && user.email"
        class="text-sm text-gray-500 font-normal flex gap-2 items-center truncate break-words"
      >
        <v-clamp autoresize :max-lines="1">
          {{ user.email }}
        </v-clamp>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PersonBasic',

  props: {
    email: {
      type: Boolean,
      default: true,
    },
    number: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default() {
        return {
          full_name: 'Name',
          number: '12345',
        }
      },
    },
  },

  data() {
    return {}
  },

  computed: {
    loggedUser() {
      return this.$store.getters.getUser
    },
    itsYou() {
      return this.loggedUser && this.user && this.loggedUser.key === this.user.key
    },
  },

  methods: {
    getPersonName() {
      return !this.fullname ? this.user.display_name || this.user.full_name : this.user.full_name
    },
  },
}
</script>
